import axios from "axios";
// import Cookies from "js-cookie";

const basePath = process.env.NODE_ENV === "development" ? "https://dev-mev.id.md/" : "/";

// required in case of opening a project where the backend will be run locally
// const basePath = process.env.NODE_ENV === "development" ? "http://0.0.0.0:9001/" : "/";

export function login(data) {

    let newConf = {
        url: `${basePath}admin/login`,
        method: 'post',
        data: data,
    }

    const response = axios(newConf)
    return response;
}

export function getTaxReport(data) {
    let newConf = {
        url: `https://ekassa.id.md/api/acps/download/report/taxes/excel?`,
        method: 'get',
        data: data,
        responseType: 'blob', 
    }

    const response = axios(newConf)
    return response;
}
