import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import { useNavigate } from "react-router-dom";
import { getOrganizations } from "../../services/requests/GraphRequests";
import { translation } from "../../services/translation/translation";

import RU from "../../services/translation/ru.json";
import Pagination from "../Pagination";

export default function CompanyList() {
    let navigate = useNavigate();
    const limit = 5;
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(true);
    const [companyList, setCompanyList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [skip, setSkip] = useState(0);
    const [dict, setDict] = useState(RU);

    useEffect(() => {
        getOrganizations(skip, limit).then((resp) => {
            setCompanyList(resp.data.getOrganizations.data.reverse());
            setTotal(resp.data.getOrganizations.count);
            setLoading(false);
        });
        if (Cookies.get("simpalsid.lang")) {
            setDict(translation(Cookies.get("simpalsid.lang")));
        } else {
            setDict(translation("ru"));
        }
    }, [currentPage, skip]);

    if (loading) {
        return (
            <div align="center">
                <CircularProgress />
            </div>
        );
    }

    const paginate = (page) => {
        setCurrentPage((prevPage) => {
            if (prevPage !== page) {
                setSkip(limit * (page - 1));
            }
            return page;
        });
    };

    return (
        <Box
            sx={{ flexGrow: 1, marginTop: 3, marginLeft: 10, marginBottom: 10 }}
        >
            <Grid container sx={{ marginTop: 2 }} spacing={4} align="left">
                <Grid item xs={6}>
                    <Typography
                        sx={{ fontSize: 24 }}
                        color="pimary"
                        gutterBottom
                    >
                        {dict.list_companies}
                    </Typography>
                </Grid>
                <Grid item xs={5} align="right">
                    <Button
                        variant="outlined"
                        // sx={{width: '100%'}}
                        // disabled={!vererification || !showReceipt}
                        onClick={() => {
                            navigate("/new-company");
                        }}
                    >
                        {dict.add_new_org}
                    </Button>
                </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ marginTop: 3 }} align="left">
                <Grid item xs={11}>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>id</TableCell>
                                    <TableCell align="right">
                                        {dict.name}
                                    </TableCell>
                                    <TableCell align="right">
                                        {dict.contact}
                                    </TableCell>
                                    <TableCell align="right">
                                        {dict.phone_number}
                                    </TableCell>
                                    <TableCell align="right">
                                        {dict.email}
                                    </TableCell>
                                    <TableCell align="right">
                                        {dict.idno}
                                    </TableCell>
                                    <TableCell align="right">
                                        {dict.nr_cashRegisters}
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {companyList.map((company) => {
                                    return (
                                        <TableRow
                                            hover
                                            key={company?.id}
                                            sx={{
                                                "td, th": { cursor: "pointer" },
                                                "&:last-child td, &:last-child th":
                                                    { border: 0 },
                                            }}
                                            onClick={() => {
                                                navigate(
                                                    `/company/${company?.id}`
                                                );
                                            }}
                                        >
                                            <TableCell align="left">
                                                {company?.id}
                                            </TableCell>
                                            <TableCell align="right">
                                                {company?.name}
                                            </TableCell>
                                            <TableCell align="right">
                                                {company?.contact}
                                            </TableCell>
                                            <TableCell align="right">
                                                {company?.contactPhone}
                                            </TableCell>
                                            <TableCell align="right">
                                                {company?.contactEmail}
                                            </TableCell>
                                            <TableCell align="right">
                                                {company?.idno}
                                            </TableCell>
                                            <TableCell align="right">
                                                {company?.cashRegistersCount}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {total > 5 && (
                        <Pagination
                            limit={limit}
                            pageCount={total / 5}
                            currentPage={currentPage}
                            handleChange={paginate}
                        />
                    )}
                </Grid>
            </Grid>
        </Box>
    );
}
