import React from 'react';

import Cookies from 'js-cookie';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import TextField from '@mui/material/TextField';

import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { getCashById, cashRegisterApiKeyRefresh } from '../../services/requests/GraphRequests';
import { translation } from '../../services/translation/translation';
import RU from '../../services/translation/ru.json'

export default function CashRegister() {
  let navigate = useNavigate();
  let params = useParams();
  const [loading, setLoading] = React.useState(true);
  const [companyWithCash, setCompanyWithCash] = React.useState({});
  const [dict, setDict] = React.useState(RU);
  React.useEffect(() => {
    getCashById(params.companyId, params.cashRegisterId)
    .then((data) => {
      console.log("data: ", data.data.getOrganization);
      setCompanyWithCash(data.data.getOrganization);
      setLoading(false);
    }).catch((error) => {
      console.log(error);
      setLoading(false);
    });
    if (Cookies.get('simpalsid.lang')) {
      setDict(translation(Cookies.get('simpalsid.lang')));
    } else {
      setDict(translation("ru"));
    }
  }, [])

  const [openSuccess, setOpenSuccess] = React.useState(false);
  const [error, setError] = React.useState({
      status: false,
      msg: "Error"
  });
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSuccess(false);
    setError({
      status: false,
      msg: "Error"
    });
  };



  // https://stackoverflow.com/a/65996386
  // return a promise
  function copyToClipboard(textToCopy) {
  // navigator clipboard api needs a secure context (https)
  if (navigator.clipboard && window.isSecureContext) {
      // navigator clipboard api method'
      return navigator.clipboard.writeText(textToCopy);
  } else {
      // text area method
      let textArea = document.createElement("textarea");
      textArea.value = textToCopy;
      // make the textarea out of viewport
      textArea.style.position = "fixed";
      textArea.style.left = "-999999px";
      textArea.style.top = "-999999px";
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      return new Promise((res, rej) => {
          // here the magic happens
          document.execCommand('copy') ? res() : rej();
          textArea.remove();
      });
    }
  }


  React.useEffect(() => {
    if(openSuccess && loading){
      setTimeout(function () {
        getCashById(params.cashRegisterId)
        .then((data) => {
          // console.log("data: ", data.data.getOrganization);
          setCompanyWithCash(data.data.getOrganization);
        });
        setLoading(false);
    }, 300);
    }
  }, [openSuccess, setOpenSuccess]);

  function refreshApiKey() {
    cashRegisterApiKeyRefresh(companyWithCash.cashRegisters[0].id)
    .then((data) => {
      // console.log("data: ", data.data.cashRegisterApiKeyRefresh);
      // console.log("Success: ", data);
      window.location.reload(false);
    })
  }

  if (loading) {
    return (
      <div align='center'>
          <CircularProgress />
      </div>
    )
  }

  // console.log("companyWithCash:", companyWithCash)
  if(!companyWithCash) return null;

  return (
    <Box sx={{ flexGrow: 1, marginTop: 3, marginLeft: 10, marginBottom: 10 }}>
      <Grid container sx={{ marginTop: 2 }} spacing={4} align='left'>
        <Grid item xs={6}>
          <Typography sx={{ fontSize: 24}} color="pimary" gutterBottom>
            {dict.cash_machine} № {companyWithCash.cashRegisters[0].id}
          </Typography>
          <Button variant="text" color="secondary" onClick={() => { navigate(`/company/${companyWithCash.id}`);}}>&#60; {dict.go_back}</Button>
          {/* Organization info */}
          <Grid container spacing={2} align='left'>
            <Grid item xs={4}>
              <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
              {dict.org_name}:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography sx={{ fontSize: 14 }} color="pimary" gutterBottom>
                {companyWithCash.name}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                {dict.idno} :
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography sx={{ fontSize: 14 }} color="pimary" gutterBottom>
                {companyWithCash.idno}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                {dict.contact} :
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography sx={{ fontSize: 14 }} color="pimary" gutterBottom>
                {companyWithCash.contact}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                {dict.phone_number} :
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography sx={{ fontSize: 14 }} color="pimary" gutterBottom>
                {companyWithCash.contactPhone}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                {dict.email} :
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography sx={{ fontSize: 14 }} color="pimary" gutterBottom>
                {companyWithCash.contactEmail}
              </Typography>
            </Grid>
            {companyWithCash.organizationPhone && (
              <>
                <Grid item xs={4}>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    {dict.organization_phone} :
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography sx={{ fontSize: 14 }} color="pimary" gutterBottom>
                    {companyWithCash.organizationPhone}
                  </Typography>
                </Grid>
              </>
            )}
            {companyWithCash.organizationEmail && (
              <>
                <Grid item xs={4}>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    {dict.organization_email} :
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography sx={{ fontSize: 14 }} color="pimary" gutterBottom>
                    {companyWithCash.organizationEmail}
                  </Typography>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
        <Grid item xs={5} align='right'>
          <Button variant="outlined"
          // sx={{width: '100%'}}
          // disabled={true}
          onClick={() => {navigate(`/edit-cash-register/${params.cashRegisterId}/${params.companyId}`);}}
          >
              {dict.edit_cash_register}
          </Button>
        </Grid>
      </Grid>
      <Grid container sx={{ marginTop: 2 }} spacing={4} align='left'>
        <Grid item xs={5}>


          <TextField
            sx={{ m: 1, width: '100%' }}
            id="Address"
            label={dict.address}
            value={companyWithCash.cashRegisters[0].address}
            variant="standard"
          />

          <TextField
            sx={{ m: 1, width: '100%' }}
            id="Model"
            label="Model"
            value={companyWithCash.cashRegisters[0].model}
            variant="standard"
          />

          <TextField
            sx={{ m: 1, width: '100%' }}
            id="Domain"
            label={dict.domain}
            value={companyWithCash.cashRegisters[0].domain}
            variant="standard"
          />

          <TextField
            sx={{ m: 1, width: '100%' }}
            id="Kind Of Activity"
            label={dict.kind_activity}
            value={companyWithCash.cashRegisters[0].kindOfActivity}
            variant="standard"
          />


        </Grid>
        <Grid item xs={6}>
          <TextField
            sx={{ m: 1, width: '100%' }}
            id="Factory Number"
            label={dict.factory_number}
            value={companyWithCash.cashRegisters[0].factory}
            variant="standard"
          />

          <TextField
            sx={{ m: 1, width: '100%' }}
            id="Registration Number"
            label={dict.registration_number}
            value={companyWithCash.cashRegisters[0].number}
            variant="standard"
          />

          <TextField
            sx={{ m: 1, width: '100%' }}
            id="Key"
            label={dict.key}
            value={companyWithCash.cashRegisters[0].key}
            variant="standard"
          />

        </Grid>

      </Grid>
      <Snackbar open={openSuccess} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
            Api key refreshed !
          </Alert>
      </Snackbar>
      <Snackbar open={error.status} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
              {error.msg}
          </Alert>
      </Snackbar>
    </Box>
  )
}
