import React, { useState, useEffect } from "react";

import Cookies from "js-cookie";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { getOrganizationById } from "../../services/requests/GraphRequests";
import { translation } from "../../services/translation/translation";

import RU from "../../services/translation/ru.json";
import Pagination from "../Pagination";

export default function Company() {
    let navigate = useNavigate();
    let { companyId = "" } = useParams();
    const limit = 5;
    const [loading, setLoading] = useState(true);
    const [company, setCompany] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [skip, setSkip] = useState(0);
    const [dict, setDict] = useState(RU);

    useEffect(() => {
        getOrganizationById(companyId, skip, limit).then((data) => {
            let company = data.data.getOrganization;
            company.cashRegisters.reverse();
            setCompany(company);
            setLoading(false);
        });
        if (Cookies.get("simpalsid.lang")) {
            setDict(translation(Cookies.get("simpalsid.lang")));
        } else {
            setDict(translation("ru"));
        }
    }, [companyId, skip]);

    if (loading) {
        return (
            <div align="center">
                <CircularProgress />
            </div>
        );
    }
    const paginate = (page) => {
        setCurrentPage((prevPage) => {
            if (prevPage !== page) {
                setSkip(limit * (page - 1));
            }
            return page;
        });
    };

    return (
        <Box
            sx={{ flexGrow: 1, marginTop: 3, marginLeft: 10, marginBottom: 10 }}
        >
            <Grid container sx={{ marginTop: 2 }} spacing={4} align="left">
                <Grid item xs={6}>
                    <Typography
                        sx={{ fontSize: 24 }}
                        color="pimary"
                        gutterBottom
                    >
                        {dict.organization} №{companyId}
                    </Typography>
                    <Button
                        variant="text"
                        color="secondary"
                        onClick={() => {
                            navigate("/");
                        }}
                    >
                        &#60; {dict.go_back}
                    </Button>
                </Grid>
                <Grid item xs={5} align="right">
                    <Button
                        variant="outlined"
                        // sx={{width: '100%'}}
                        // disabled={true}
                        onClick={() => {
                            navigate(`/edit-company/${companyId}`);
                        }}
                    >
                        {dict.edit_org}
                    </Button>
                </Grid>
                {/* Organization info */}
                <Grid container item xs={12} spacing={1} align="left">
                    <Grid item xs={6}>
                        <Grid container spacing={1} align="left">
                            <Grid item xs={4}>
                                <Typography
                                    sx={{ fontSize: 14 }}
                                    color="text.secondary"
                                    gutterBottom
                                >
                                    {dict.idno}:
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography
                                    sx={{ fontSize: 14 }}
                                    color="pimary"
                                    gutterBottom
                                >
                                    {company.idno}
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography
                                    sx={{ fontSize: 14 }}
                                    color="text.secondary"
                                    gutterBottom
                                >
                                    {dict.organization_name}:
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography
                                    sx={{ fontSize: 14 }}
                                    color="pimary"
                                    gutterBottom
                                >
                                    {company.name}
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography
                                    sx={{ fontSize: 14 }}
                                    color="text.secondary"
                                    gutterBottom
                                >
                                    {dict.contract_number}:
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography
                                    sx={{ fontSize: 14 }}
                                    color="pimary"
                                    gutterBottom
                                >
                                    {company.contract}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={5}>
                        <Grid container spacing={1} align="left">
                            <Grid item xs={4}>
                                <Typography
                                    sx={{ fontSize: 14 }}
                                    color="text.secondary"
                                    gutterBottom
                                >
                                    {dict.contact_person}:
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography
                                    sx={{ fontSize: 14 }}
                                    color="pimary"
                                    gutterBottom
                                >
                                    {company.contact}
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography
                                    sx={{ fontSize: 14 }}
                                    color="text.secondary"
                                    gutterBottom
                                >
                                    {dict.contact_person_phone}:
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography
                                    sx={{ fontSize: 14 }}
                                    color="pimary"
                                    gutterBottom
                                >
                                    {company.contactPhone}
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography
                                    sx={{ fontSize: 14 }}
                                    color="text.secondary"
                                    gutterBottom
                                >
                                    {dict.contact_person_email}:
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography
                                    sx={{ fontSize: 14 }}
                                    color="pimary"
                                    gutterBottom
                                >
                                    {company.contactEmail}
                                </Typography>
                            </Grid>
                            {company.organizationPhone && (
                                <>
                                    <Grid item xs={4}>
                                        <Typography
                                            sx={{ fontSize: 14 }}
                                            color="text.secondary"
                                            gutterBottom
                                        >
                                            {dict.organization_phone}:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography
                                            sx={{ fontSize: 14 }}
                                            color="pimary"
                                            gutterBottom
                                        >
                                            {company.organizationPhone}
                                        </Typography>
                                    </Grid>
                                </>
                            )}
                            {company.organizationEmail && (
                                <>
                                    <Grid item xs={4}>
                                        <Typography
                                            sx={{ fontSize: 14 }}
                                            color="text.secondary"
                                            gutterBottom
                                        >
                                            {dict.organization_email}:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography
                                            sx={{ fontSize: 14 }}
                                            color="pimary"
                                            gutterBottom
                                        >
                                            {company.organizationEmail}
                                        </Typography>
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container sx={{ marginTop: 2 }} spacing={4} align="left">
                <Grid item xs={6}>
                    <Typography
                        sx={{ fontSize: 24 }}
                        color="pimary"
                        gutterBottom
                    >
                        {dict.cash_machine}:
                    </Typography>
                </Grid>
                <Grid item xs={5} align="right">
                    <Button
                        variant="outlined"
                        // sx={{width: '100%'}}
                        // disabled={true}
                        onClick={() => {
                            navigate(`/add-cash-register/${companyId}`);
                        }}
                    >
                        {dict.add_cashRegisters}
                    </Button>
                </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ marginTop: 3 }} align="left">
                <Grid item xs={11}>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">id</TableCell>
                                    <TableCell align="center">
                                        {dict.serial_number}
                                    </TableCell>
                                    <TableCell align="center">
                                        {dict.factory_number}
                                    </TableCell>
                                    <TableCell align="center">
                                        {dict.model}
                                    </TableCell>
                                    <TableCell align="center">
                                        {dict.address}
                                    </TableCell>
                                    <TableCell align="right">
                                        {dict.domain}
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {company.cashRegisters.map((cash) => {
                                    return (
                                        <TableRow
                                            hover
                                            key={cash.id}
                                            sx={{
                                                "&:last-child td, &:last-child th":
                                                    { border: 0 },
                                            }}
                                            onClick={() => {
                                                navigate(
                                                    `/cash-register/${cash.id}/${companyId}`
                                                );
                                            }}
                                        >
                                            <TableCell align="left">
                                                {cash.id}
                                            </TableCell>
                                            <TableCell align="center">
                                                {cash.number}
                                            </TableCell>
                                            <TableCell align="center">
                                                {cash.factory}
                                            </TableCell>
                                            <TableCell align="center">
                                                {cash.model}
                                            </TableCell>
                                            <TableCell align="center">
                                                {cash.address}
                                            </TableCell>
                                            <TableCell align="right">
                                                {cash.domain}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {company.cashRegistersCount > 1 && (
                        <Pagination
                            currentPage={currentPage}
                            pageCount={Math.ceil(
                                company.cashRegistersCount / limit
                            )}
                            handleChange={paginate}
                        />
                    )}
                </Grid>
            </Grid>
        </Box>
    );
}
