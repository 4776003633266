import React from 'react';

import Cookies from 'js-cookie';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import FormHelperText from '@mui/material/FormHelperText';

import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { getCashById } from '../../services/requests/GraphRequests';
import { validateCashRegister } from '../../services/Validations';
import { cashRegisterUpdate } from '../../services/requests/GraphRequests';
import { translation } from '../../services/translation/translation';
import RU from '../../services/translation/ru.json'

export default function EditCashRegister() {
  let navigate = useNavigate();
  let params = useParams();
  const [loading, setLoading] = React.useState(true);
  const [cashRegister, setCashRegister] = React.useState({});
  const [companyWithCash, setCompanyWithCash] = React.useState({});
  const [dict, setDict] = React.useState(RU);

  const [formValidationError, setFormValidationError] = React.useState({
    address: false,
    model: false,
    domain: false,
    kindOfActivity: false,
    factory: false,
    number: false,
    key: false,
  });

  React.useEffect(() => {
    getCashById(params.companyId, params.cashRegisterId)
    .then((data) => {
      // console.log("data: ", data.data.getOrganization);
      setCompanyWithCash(data.data.getOrganization);
      setCashRegister(data.data.getOrganization.cashRegisters[0]);
      setLoading(false);

    });
    if (Cookies.get('simpalsid.lang')) {
      setDict(translation(Cookies.get('simpalsid.lang')));
    } else {
      setDict(translation("ru"));
    }
  }, [])

  let [readyToSave, setReadyToSave] = React.useState(false);
  let [thereAreChanges, setThereAreChanges] = React.useState(false);

  const [openSuccess, setOpenSuccess] = React.useState(false);
  const [error, setError] = React.useState({
      status: false,
      msg: "Error"
  });

  React.useEffect(() => {
      if (thereAreChanges) {
        let validateResult = validateCashRegister(cashRegister)
        setReadyToSave(validateResult.validation);
        setFormValidationError(validateResult.errors)
      }
  }, [cashRegister, setCashRegister]);

  React.useEffect(() => {
    if(openSuccess){
      setTimeout(function () {
        navigate(`/cash-register/${cashRegister.id}/${params.companyId}`);
    }, 1500);
    }
  }, [openSuccess, setOpenSuccess]);

  const handleChange = (event) => {
    setCashRegister({
        ...cashRegister,
        [event.target.name]: event.target.value,
    });
    setThereAreChanges(true);
  };

  const handleIsTest = (event) => {
    setCashRegister({
      ...cashRegister
    });
    setThereAreChanges(true)
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSuccess(false);
    setError({
      status: false,
      msg: "Error"
    });
  };


  function saveCashRegister() {
    cashRegisterUpdate(cashRegister)
    .then((data) => {
      // console.log("data: ", data.data.cashRegisterUpdate);
      // console.log("Success: ", data);
      if(data.data.data.cashRegisterUpdate !== null){
        navigate(`/cash-register/${cashRegister.id}/${params.companyId}`);
      } else {
        let msgError = "There are errors while receipt generation"
          if (data.data.errors.length > 0){
            msgError = data.data.errors[0].message
          }
          setError({
              status: true,
              msg:  msgError,
          });
      }

    })
    console.log("saved");

  }

  if (loading) {
    return (
      <div align='center'>
          <CircularProgress />
      </div>
    )
  }
  // console.log("companyWithCash:", companyWithCash);
  // console.log("cashRegister:", cashRegister);
  return (
    <Box sx={{ flexGrow: 1, marginTop: 3, marginLeft: 10, marginBottom: 10 }}>
      <Grid container sx={{ marginTop: 2 }} spacing={4} align='left'>
        <Grid item xs={6}>
          <Typography sx={{ fontSize: 24}} color="pimary" gutterBottom>
            {dict.edit_cash_register} № {cashRegister.id}
          </Typography>
          <Button variant="text" color="secondary" onClick={() => { navigate(`/cash-register/${cashRegister.id}/${params.companyId}`);}}>&#60; {dict.go_back}</Button>
          {/* Organization info */}
          <Grid container spacing={2} align='left'>
            <Grid item xs={4}>
              <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                {dict.org_name}:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography sx={{ fontSize: 14 }} color="pimary" gutterBottom>
                {companyWithCash.name}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                {dict.idno}:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography sx={{ fontSize: 14 }} color="pimary" gutterBottom>
                {companyWithCash.idno}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                {dict.contact} :
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography sx={{ fontSize: 14 }} color="pimary" gutterBottom>
                {companyWithCash.contact}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                {dict.phone_number} :
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography sx={{ fontSize: 14 }} color="pimary" gutterBottom>
                {companyWithCash.contactPhone}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                {dict.email} :
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography sx={{ fontSize: 14 }} color="pimary" gutterBottom>
                {companyWithCash.contactEmail}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                {dict.organization_phone} :
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography sx={{ fontSize: 14 }} color="pimary" gutterBottom>
                {companyWithCash.organizationPhone}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                {dict.organization_email} :
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography sx={{ fontSize: 14 }} color="pimary" gutterBottom>
                {companyWithCash.organizationEmail}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={5} align='right'>
          <Button variant="outlined"
          disabled={!readyToSave}
          onClick={saveCashRegister}
          >
              {dict.save_cash_register}
          </Button>
        </Grid>
      </Grid>
      <Grid container sx={{ marginTop: 2 }} spacing={4} align='left'>
        <Grid item xs={5}>

          <FormControl  fullWidth sx={{ m: 1 }} variant="standard">
              <InputLabel htmlFor="component-simple">{dict.address}</InputLabel>
              <Input id="component-simple" value={cashRegister.address} onChange={handleChange}  name='address'
              aria-describedby="standard-helper-text"
              inputProps={{
              'aria-label': 'text',
            }}
            error={formValidationError.address}
          />
            <FormHelperText id="standard-helper-text">{dict.must_not_empty}</FormHelperText>
          </FormControl>
          <FormControl  fullWidth sx={{ m: 1 }} variant="standard">
              <InputLabel htmlFor="component-simple">{dict.model}</InputLabel>
              <Input id="component-simple" value={cashRegister.model} onChange={handleChange} name='model'
              aria-describedby="standard-helper-text"
              inputProps={{
              'aria-label': 'text',
            }}
            error={formValidationError.model}
          />
            <FormHelperText id="standard-helper-text">{dict.must_not_empty}</FormHelperText>
          </FormControl>
          <FormControl  fullWidth sx={{ m: 1 }} variant="standard">
              <InputLabel htmlFor="component-simple">{dict.domain}</InputLabel>
              <Input id="component-simple" value={cashRegister.domain} onChange={handleChange} name='domain'
              aria-describedby="standard-helper-text"
              inputProps={{
              'aria-label': 'text',
            }}
            error={formValidationError.domain}
          />
            <FormHelperText id="standard-helper-text">{dict.dmn_must_name}</FormHelperText>
          </FormControl>
          <FormControl  fullWidth sx={{ m: 1 }} variant="standard">
              <InputLabel htmlFor="component-simple">{dict.kind_activity}</InputLabel>
              <Input id="component-simple" value={cashRegister.kindOfActivity} onChange={handleChange} name='kindOfActivity'
              aria-describedby="standard-helper-text"
              inputProps={{
              'aria-label': 'text',
            }}
            error={formValidationError.kindOfActivity}
          />
            <FormHelperText id="standard-helper-text">{dict.must_not_empty}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl  fullWidth sx={{ m: 1 }} variant="standard">
              <InputLabel htmlFor="component-simple">{dict.factory_number}</InputLabel>
              <Input id="component-simple" value={cashRegister.factory} onChange={handleChange} name='factory'
              aria-describedby="standard-helper-text"
              inputProps={{
              'aria-label': 'text',
            }}
            error={formValidationError.factory}
          />
            <FormHelperText id="standard-helper-text">{dict.must_not_empty}</FormHelperText>
          </FormControl>
          <FormControl  fullWidth sx={{ m: 1 }} variant="standard">
              <InputLabel htmlFor="component-simple">{dict.registration_number}</InputLabel>
              <Input id="component-simple" value={cashRegister.number} onChange={handleChange} name='number'
              aria-describedby="standard-helper-text"
              inputProps={{
              'aria-label': 'text',
            }}
            error={formValidationError.number}
          />
            <FormHelperText id="standard-helper-text">{dict.must_10_12_char}</FormHelperText>
          </FormControl>
          <FormControl  fullWidth sx={{ m: 1 }} variant="standard">
              <InputLabel htmlFor="component-simple">{dict.key}</InputLabel>
              <Input id="component-simple" value={cashRegister.key} onChange={handleChange} name='key'
              aria-describedby="standard-helper-text"
              inputProps={{
              'aria-label': 'text',
            }}
            error={formValidationError.key}
          />
            <FormHelperText id="standard-helper-text">{dict.must_not_empty}</FormHelperText>
          </FormControl>

        </Grid>
        <Snackbar open={openSuccess} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
              Cash register saved!
            </Alert>
        </Snackbar>
        <Snackbar open={error.status} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                {error.msg}
            </Alert>
        </Snackbar>
      </Grid>
    </Box>
  )
}
