import React from "react";
import { Pagination } from "@mui/material";

export default function Paginator({ currentPage, pageCount, handleChange }) {
    return (
        <Pagination
            sx={{
                mt: 5,
                justifyContent: "center",
                display: "flex",
            }}
            count={pageCount}
            variant="outlined"
            shape="rounded"
            page={currentPage}
            onChange={(_, pageNumber) => handleChange(pageNumber)}
        />
    );
}
