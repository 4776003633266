import React from 'react';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';

import Cookies from 'js-cookie';

import Logo from '../assets/999eKassa.png';

import { useNavigate } from "react-router-dom";

import { translation } from '../services/translation/translation';
import RU from '../services/translation/ru.json'


export default function TopBar() {
  let navigate = useNavigate();

  const [auth, setAuth] = React.useState(false);
  const [dict, setDict] = React.useState(RU);

  React.useEffect(() => {
    if (Cookies.get('auth')) {
      // console.log("Cookies +")
      setAuth(true)
    } else {
      // console.log("Cookies -")
      setAuth(false)
    };
    if (Cookies.get('simpalsid.lang')) {
      setDict(translation(Cookies.get('simpalsid.lang')));
    } else {
      setDict(translation("ru"));
    }
  }, [])

  function setLang() {
    console.log("change lang");
    
    if (Cookies.get('simpalsid.lang')) {
      let currentLang = Cookies.get('simpalsid.lang');

      if(currentLang === 'ru'){
        Cookies.set('simpalsid.lang', 'ro');
      };
      if(currentLang === 'ro'){
        Cookies.set('simpalsid.lang', 'ru');
      };
    } else {
      Cookies.set('simpalsid.lang', 'ru');
    };
    window.location.reload(false);

  }

  function exitBtn() {
    Cookies.remove('auth');
    navigate("/login");
  }

  return (
    <Box sx={{ flexGrow: 1, position: 'absolute', width: '100%' }}>
      <AppBar 
      // position="absolute"
          sx={{backgroundColor: '#313238', height: '76px'}}
      >
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2, marginRight: "30px", height: '76px', width: '162px', alignItems: 'center', justifyContent:  'center',}}
          >
            <img src={Logo} sx={{height: '36px', top: '50%',}} alt='logo'
              onClick={() => { navigate("/");}}/>
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            
          </Typography>

              <Button 
                  variant="outlined"
                  sx={{color: "#acb44e", borderColor: '#acb44e', marginRight: 1, display: auth ? 'block' : 'none'}}
                  onClick={exitBtn}
              >
                  <b>Exit</b>
              </Button>

              
              <Button 
                  variant="outlined"
                  sx={{color: "#acb44e", borderColor: '#acb44e'}}
                  onClick={setLang}
              >
                  <b>{Cookies.get('simpalsid.lang') || 'RU' }</b>
              </Button>


        </Toolbar>
      </AppBar>
    </Box>

  );
}
